//@ts-strict
import { useEffect, useState } from 'react'
import { Box, Text } from '@foundation/components'
import { ErrorMessage } from '@foundation/components/Select/styles'
import { styled } from '@foundation/themes/styles'
import {
  Button,
  Divider,
  Form,
  FormActions,
  InlineEditMoneyInput,
  PrimaryButton,
  Section,
  Sections
} from 'components'
import { MINIMUM_PPL_MONTHLY_CAP } from '../constants'
import styles from './BillingFrequency.module.scss'
import { BillingFrequencyFormProps } from './types'

const StyledForm = styled(Form)`
  padding: 2.4rem;
  width: 100%;
  min-width: 60rem;
  max-width: 80rem;

  &.confirmation {
    min-width: 40rem;
    max-width: 60rem;
  }

  @media (max-width: 414px) {
    min-width: 30rem;
  }
`

const sellerCapErrorMessage = (monthlyCap: number) => {
  if (monthlyCap < MINIMUM_PPL_MONTHLY_CAP) {
    return `Cap is below $${MINIMUM_PPL_MONTHLY_CAP}`
  }

  return ''
}

/*
 * In this component coexists 2 types of monthly caps. `sellerCap` represents
 * the persisted value, in example the monthly cap the investor previously set.
 *
 * Meanwhile, currentMonthlyCap represents the input monthly cap that can be either
 * added or modified by the sales representative.
 */
export const BillingFrequencyForm = ({
  cancelText = 'Cancel',
  displayBuyerCap = false,
  buyerCap = 0,
  sellerCap = 0,
  billingFrequency = 'month',
  onCancel,
  onSubmit,
  submitText = 'Save'
}: BillingFrequencyFormProps) => {
  const [currentSellerCap, setCurrentSellerCap] = useState(sellerCap || 0)
  const [currentBuyerCap, setCurrentBuyerCap] = useState(buyerCap || 0)
  const [isLoading, setIsLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const isLessThanMinimumCap = currentSellerCap + currentBuyerCap < 200
  const newBillingFrequency = (billingFrequency || 'month') === 'month' ? 'week' : 'month'
  const newDisplayBillingFrequency =
    (billingFrequency || 'month') === 'month' ? 'Weekly' : 'Monthly'

  const subTitle = 'Please input the cap value for the upcoming cycle'

  useEffect(() => {
    setSubmitDisabled(isLessThanMinimumCap)
  }, [sellerCap, buyerCap, isLessThanMinimumCap])

  return (
    <StyledForm
      onSubmit={() => {
        setIsLoading(true)
        onSubmit(currentSellerCap, currentBuyerCap, newBillingFrequency)
      }}
    >
      <Box marginBottom="$4">
        <Text weight="bold">Set the caps and billing frequency</Text>
      </Box>

      <Text size="sm" as="span">
        {subTitle}
      </Text>
      <Divider className={styles.divider} />

      <Sections>
        <Section>
          {displayBuyerCap && (
            <>
              <Box marginBottom="$2">
                <Text>Buyer Cap</Text>
              </Box>

              <InlineEditMoneyInput
                className={styles.input}
                value={String(currentBuyerCap)}
                onChange={buyerCap => setCurrentBuyerCap(Number(buyerCap.replace(/[^\d.]/g, '')))}
              />
            </>
          )}

          <Box marginBottom="$2" marginTop="$2">
            <Text>Seller Cap</Text>
          </Box>

          <InlineEditMoneyInput
            className={styles.input}
            value={String(currentSellerCap)}
            onChange={cap => setCurrentSellerCap(Number(cap.replace(/[^\d.]/g, '')))}
          />

          <ErrorMessage hasError={isLessThanMinimumCap}>
            <Box marginTop="$3" marginBottom="$5">
              <Text color="danger5" size="xs">
                {sellerCapErrorMessage(currentSellerCap + currentBuyerCap)}
              </Text>
            </Box>
          </ErrorMessage>

          <Box marginBottom="$2" marginTop="$5">
            <Text>New Billing Frequency</Text>
          </Box>

          <Box marginBottom="$2" marginTop="$2">
            <Text weight="bold">{newDisplayBillingFrequency}</Text>
          </Box>
        </Section>
      </Sections>

      <FormActions>
        <Button type="button" disabled={isLoading} onClick={onCancel}>
          {cancelText}
        </Button>
        <PrimaryButton type="submit" disabled={submitDisabled} isLoading={isLoading}>
          {submitText}
        </PrimaryButton>
      </FormActions>
    </StyledForm>
  )
}
