import { getPplBillingInfoUrl } from 'services/getUrl'
import { putRequest } from 'utils'

export const updatePayPerLeadBillingInfoCycle = async (
  providerId: string | number,
  providerType: string,
  buyerCap: number,
  sellerCap: number,
  billingFrequency: string,
  onSuccess?: () => void
) => {
  const url = getPplBillingInfoUrl({
    pathParams: {
      provider_type: providerType,
      provider_id: providerId
    },
    action: 'update-cycle'
  })

  await putRequest(url, {
    buyer_monthly_cap: buyerCap,
    monthly_cap: sellerCap,
    billing_freq: billingFrequency
  })

  onSuccess?.()
}
