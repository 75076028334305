import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Block,
  Pagination,
  SearchConsole,
  SearchInputParams,
  SearchStatusPrompt,
  Table,
  TableResults,
  TableCell as TD,
  TableHeaderColumn as TH,
  TableHeaderRow as THR,
  TableRow as TR
} from 'components'
import { Contents } from 'components/Contents/index'
import { Page } from 'components/Page/index'
import { useDocumentTitle, useQuery } from 'hooks'
import { getInvestorsUrl } from 'services/getUrl/getUrl'
import { deserializeAsync, getRequest, queryStringToObject } from 'utils'

export function InvestorsPage() {
  const { search } = useLocation()
  const { sortBy, sortDir, searchTerm } = queryStringToObject(search)
  const sort = `${sortDir === 'asc' ? '' : '-'}${
    sortBy ? sortBy.replace(' ', '_').toLowerCase() : 'id'
  }`
  const [page, setPage] = useState('1')
  const { data, isLoading } = useQuery(['investors', page, search], async () => {
    const res = await getRequest(
      getInvestorsUrl({
        searchParams: {
          sort,
          search: searchTerm
        }
      })
    )
    return {
      investors: await deserializeAsync(res.data),
      total: res.data?.meta?.total
    }
  })

  const pageTitle = 'Investors'
  useDocumentTitle(pageTitle)

  return (
    <Page>
      <Contents title={pageTitle}>
        <SearchStatusPrompt type="Investor" count={data?.total} />
        <SearchConsole search={<SearchInputParams placeholder="Search by name or email" />} />
        <Table>
          <THR>
            <TH sort={[{ name: 'ID', slug: 'id' }]} style={{ width: '64px' }} />
            <TH sort={[{ name: 'Name', slug: 'name' }]} />
            <TH sort={[{ name: 'Email', slug: 'email' }]} />
            <TH sort={[{ name: 'Billing Email', slug: 'billing-email' }]} />
            <TH sort={[{ name: 'Ppl Investor', slug: 'ppl_investor' }]} />
          </THR>
          <TableResults type="users" colSpan={4} isLoading={isLoading}>
            {data?.investors.map(investor => (
              <TR key={investor.id}>
                <TD>{investor.id || '-'}</TD>
                <TD>
                  <Block title={<Link to={`/investors/${investor.id}`}>{investor.name}</Link>} />
                </TD>
                <TD>{investor.email || '-'}</TD>
                <TD>{investor.billingEmail || '-'}</TD>
                <TD>{investor.pplInvestor.toString() || '-'}</TD>
              </TR>
            ))}
          </TableResults>
        </Table>
        {!isLoading && (
          <Pagination
            totalPages={Math.ceil(data?.total / 25)}
            currentPage={page}
            onChange={val => setPage(val)}
          />
        )}
      </Contents>
    </Page>
  )
}
