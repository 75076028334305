// @ts-strict
import { useMutation } from 'hooks'
import { updatePayPerLeadBillingInfoCycle, updatePayPerLeadBillingInfoReleaseCycle } from 'services'

export const usePayPerLeadBillingInfoController = (
  providerId: string | number,
  providerType: string
) => {
  const updateBillingCycle = useMutation(
    (payload: { billingFrequency: string; buyerCap: number; sellerCap: number }) =>
      updatePayPerLeadBillingInfoCycle(
        providerId,
        providerType,
        payload.buyerCap,
        payload.sellerCap,
        payload.billingFrequency
      )
  )

  const releaseBillingCycle = useMutation(() =>
    updatePayPerLeadBillingInfoReleaseCycle(providerId, providerType)
  )

  return {
    updateBillingCycle,
    releaseBillingCycle
  }
}
