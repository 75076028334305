import { environment } from 'environment'
import { getApiRoot } from './getApiRoot'
import { hapiUrls } from './hapiUrls'
import { monolithUrls } from './monolithUrls'

type TSearchParams = { [key: string]: any }
type TGetUrlProps = {
  action?: string
  id?: string
  pathParams?: TSearchParams
  searchParams?: TSearchParams
}
const getUrl = (
  servicePath = '',
  { id, action, searchParams, pathParams }: TGetUrlProps = {},
  rootApi = ''
): string => {
  let servicePathCopy = servicePath

  if (pathParams) {
    Object.entries(pathParams).forEach(([name, value]) => {
      if (value) {
        servicePathCopy = servicePathCopy.replace(`:${name}`, value)
      }
    })
  }

  const url = new URL(
    `${rootApi}${servicePathCopy}${id ? `/${id}` : ''}${action ? `/${action}` : ''}`
  )

  if (searchParams) {
    Object.entries(searchParams).forEach(([name, value]) => {
      if (value) {
        url.searchParams.append(name, value)
      }
    })
  }
  return url.href
}

export const lookupsUrl = getUrl(hapiUrls.lookups, {}, getApiRoot())

const getUrlGenerator =
  (url = '', rootApi = getApiRoot()) =>
  (params?: TSearchParams) =>
    getUrl(url, params, rootApi)

export const getAgentAeAreas = getUrlGenerator(hapiUrls.agentAeAreas)
export const getAgentLeadsUrl = getUrlGenerator(hapiUrls.agentLeads)
export const getAgentPartnershipsUrl = getUrlGenerator(hapiUrls.agentPartnerships)
export const getPresignedS3Url = getUrlGenerator(hapiUrls.presignedS3Link)
export const getAgentTeamMembershipsUrl = getUrlGenerator(hapiUrls.agentTeamMemberships)
export const getAgentTeamUrl = getUrlGenerator(hapiUrls.agentTeam)
export const getAgentTeamAgentUrl = getUrlGenerator(hapiUrls.agentTeamAgent)
export const getAgentUrl = getUrlGenerator(hapiUrls.agent)
export const getAgentsSearchUrl = getUrlGenerator(hapiUrls.agentsSearch)
export const getAgentsTerritoriesUrl = getUrlGenerator(hapiUrls.agentsTerritories)
export const getAgentsUrl = getUrlGenerator(hapiUrls.agents)
export const getBlendUsersUrl = getUrlGenerator(hapiUrls.blendUsers)
export const getBBYSLeadDispositionUrl = getUrlGenerator(hapiUrls.bbysLeadDisposition)
export const getCashOfferLeadsUrl = getUrlGenerator(hapiUrls.cashOfferLeads)
export const getCheckoutSessionsUrl = getUrlGenerator(hapiUrls.checkoutSessions)
export const getCheckoutSessionsMagicLinkUrl = getUrlGenerator(hapiUrls.checkoutSessionsMagicLink)
export const getClientsUrl = getUrlGenerator(hapiUrls.clients)
export const getConstantsUrl = getUrlGenerator(hapiUrls.constants)
export const getCurrentUserUrl = getUrlGenerator(hapiUrls.currentUser)
export const getDialerUrl = getUrlGenerator(hapiUrls.dialer)
export const getDialerServiceLeadsUrl = getUrlGenerator(hapiUrls.dialerServiceLeads)
export const getDocusignOauth = getUrlGenerator(hapiUrls.docusignOauth)
export const getDocumentsUrl = getUrlGenerator(hapiUrls.documents)
export const getEligibilityCheckUrl = getUrlGenerator(hapiUrls.eligibilityCheck)
export const getEnvelopesUrl = getUrlGenerator(hapiUrls.envelopes)
export const getFilesUrl = getUrlGenerator(hapiUrls.files)
export const getHandmatchLeadsUrl = getUrlGenerator(hapiUrls.handMatchLeads)
export const getHomeValueEstimateUrl = getUrlGenerator(hapiUrls.homeValueEstimate)
export const getInvestorsUrl = getUrlGenerator(hapiUrls.investors)
export const getLeadProviderLeadsUrl = getUrlGenerator(hapiUrls.leadProviderLeads)
export const getLeadDispositionUrl = getUrlGenerator(hapiUrls.leadDisposition)
export const getLeadTeamMembershipsUrl = getUrlGenerator(hapiUrls.leadTeamMemberships)
export const getLeads2Url = getUrlGenerator(hapiUrls.leads2)
export const getLeads3Url = getUrlGenerator(hapiUrls.leads3)
export const getLeads4Url = getUrlGenerator(hapiUrls.leads4)
export const getLeads5Url = getUrlGenerator(hapiUrls.leads5)
export const getLeadsUrl = getUrlGenerator(hapiUrls.leads)
export const getLeadGroupableUrl = getUrlGenerator(hapiUrls.leadGroupables)
export const getLeadGroupableDefinitionUrl = getUrlGenerator(hapiUrls.leadGroupableDefinitions)
export const getListingsUrl = getUrlGenerator(hapiUrls.listings)
export const getLoanOfficersUrl = getUrlGenerator(hapiUrls.loanOfficers)
export const getMarketplaceProgramsUrl = getUrlGenerator(hapiUrls.marketplacePrograms)
export const getOpportunitiesUrl = getUrlGenerator(hapiUrls.opportunities)
export const getOrdersUrl = getUrlGenerator(hapiUrls.orders)
export const getSalesOrdersUrl = getUrlGenerator(hapiUrls.salesOrders)
export const getPartnersForBbysLeadsUrl = getUrlGenerator(hapiUrls.partnersForBbysLeads)
export const getPhotosUrl = getUrlGenerator(hapiUrls.photos)
export const getPropertyQualificationUrl = getUrlGenerator(hapiUrls.propertyQualification)
export const getPropertyValuationUrl = getUrlGenerator(hapiUrls.propertyValuation)
export const getProvidableAgentLeadUrl = getUrlGenerator(hapiUrls.providableAgentLead)
export const getProviderLeadsUrl = getUrlGenerator(hapiUrls.providerLeads)
export const getProviderLeadCapitalPartnersUrl = getUrlGenerator(
  hapiUrls.providerLeadCapitalPartners
)
export const getPplPaymentInfosUrl = getUrlGenerator(hapiUrls.pplPaymentInfos)
export const getPplInvestorPaymentInfosUrl = getUrlGenerator(hapiUrls.pplInvestorPaymentInfos)
export const getPplProviderPaymentInfosUrl = getUrlGenerator(hapiUrls.pplProviderPaymentInfos)
export const getPplReferralsUrl = getUrlGenerator(hapiUrls.pplReferrals)
export const getPplBillingInfoUrl = getUrlGenerator(hapiUrls.pplBillingInfo)
export const getPplSettingsUrl = getUrlGenerator(hapiUrls.pplSettings)
export const getPureCloudTokenActiveUrl = getUrlGenerator(hapiUrls.pureCloudTokenActive)
export const getReferralsUrl = getUrlGenerator(hapiUrls.referrals)
export const getSendLeadToDialerUrl = getUrlGenerator(hapiUrls.sendLeadToDialer)
export const getStopLeadNurtureUrl = getUrlGenerator(hapiUrls.stopLeadNurture)
export const getUsers2Url = getUrlGenerator(hapiUrls.users2)
export const getTasksUrl = getUrlGenerator(hapiUrls.tasks)
export const getTasks2Url = getUrlGenerator(hapiUrls.tasks2)
export const getTasksCount = getUrlGenerator(hapiUrls.tasksCount)
export const getTeamMembershipsUrl = getUrlGenerator(hapiUrls.teamMemberships)
export const getSubTeamMembershipsUrl = getUrlGenerator(hapiUrls.subTeamMemberships)
export const getTeamsUrl = getUrlGenerator(hapiUrls.teams)
export const getTextCountsUrl = getUrlGenerator(hapiUrls.textingCounts)
export const getTextInboxUrl = getUrlGenerator(hapiUrls.textingInbox)
export const getTextsUrl = getUrlGenerator(hapiUrls.texts)
export const getTradeInLeadDispositionUrl = getUrlGenerator(hapiUrls.tradeInLeadDisposition)
export const getTradeInLeadsUrl = getUrlGenerator(hapiUrls.tradeInLeads)
export const getBBYSLeadsUrl = getUrlGenerator(hapiUrls.bbysLeads)
export const getTransactionsUrl = getUrlGenerator(hapiUrls.transactions)
export const getUserAuthUrl = getUrlGenerator(hapiUrls.userAuth)
export const postUserGoogleAuthUrl = getUrlGenerator(
  monolithUrls.userGoogleAuth,
  environment.apiMonolith
)
export const getUserManagementUrl = getUrlGenerator(hapiUrls.userManagement)
export const getUsersUrl = getUrlGenerator(hapiUrls.users)
export const getUsersAgentsUrl = getUrlGenerator(hapiUrls.usersAgents)
export const getExternalLinksUrl = getUrlGenerator(hapiUrls.externalLinks)
export const getNylasUrl = getUrlGenerator(hapiUrls.nylas)
export const getAgentLeads2Url = getUrlGenerator(hapiUrls.agentLeads2)
export const getUserIntegrationsUrl = getUrlGenerator(hapiUrls.userIntegrations)
export const getHlSimpleSalesUrl = getUrlGenerator(hapiUrls.hlSimpleSales)
export const getWebSocketTicketsUrl = getUrlGenerator(hapiUrls.websocketTickets)
export const getInspectionUrl = getUrlGenerator(hapiUrls.inspection)
export const getResolvePendingActionsUrl = getUrlGenerator(hapiUrls.resolvePendingActions)
export const getLeadWarmTransferUrl = getUrlGenerator(hapiUrls.leadWarmTransfer)
export const getActivityFeedUrl = getUrlGenerator(hapiUrls.activityFeed)
export const getExchangeTokenUrl = getUrlGenerator(
  monolithUrls.exchangeTokens,
  environment.apiMonolith
)
export const getReassignPreferredEscrowOfficerUrl = getUrlGenerator(
  hapiUrls.reassignPreferredEscrowOfficer
)
export const getSubmitEscrowOrderUrl = getUrlGenerator(hapiUrls.submitEscrowOrder)
export const getSubmitInstantOrderUrl = getUrlGenerator(hapiUrls.submitInstantOrder)
export const getServiceOfficesUrl = getUrlGenerator(hapiUrls.serviceOffices)
export const getSyncEscrowOrderUrl = getUrlGenerator(hapiUrls.syncEscrowOrder)
export const getEditEscrowOrderUrl = getUrlGenerator(hapiUrls.escrowOfficeLead)
export const getUserAuthPureCloudRedirectUrl = getUrlGenerator(hapiUrls.userAuthPureCloudRedirect)
export const getChangeAgentTeamPreferredEscrowOfficerUrl = getUrlGenerator(
  hapiUrls.changeAgentTeamPreferredEscrowOfficer
)
export const getLenderLeadsUrl = getUrlGenerator(hapiUrls.lenderLeads)
export const getSearchLoanOfficersUrl = getUrlGenerator(hapiUrls.searchLoanOfficers)
export const getLendersUrl = getUrlGenerator(hapiUrls.lenders)
export const getPropertyUuid = getUrlGenerator(hapiUrls.propertyUuid)
export const getBBYSRequestValuationUrl = getUrlGenerator(hapiUrls.bbysRequestValuation)
export const getBBYSRequestIRContractUrl = getUrlGenerator(hapiUrls.bbysRequestIRContract)
export const getInvestorSubsUrl = getUrlGenerator(hapiUrls.investorSubs)
