// @ts-strict
import { Dialer, Link, Marker, Point } from 'classes'
import { getLeadStageNameFromSlug, TLeadUserTypeServerSlug } from 'lookups'
import {
  CurrentUser,
  EligibilityCheck,
  FileAttachment,
  LeadChannel,
  LeadUser,
  LeadViewers,
  Order,
  PendingAction,
  PhoneNumber,
  Task
} from 'models'
import { Client } from 'models/Client'
import { LeadResponse } from 'schema'
import { byAttr, formatPrice, titleize } from 'utils'
import { Model } from '../../Model'
import {
  getAnyLeadPathName,
  getClient,
  getEligibilityDetails,
  getLeadUsers,
  getOrder,
  getParentLead,
  getPhoneNumbers,
  getTasks
} from '../utils'

export type TLeadConstructor = Omit<
  Lead,
  'toMarker' | 'toPoint' | 'getLink' | 'getPathname' | 'getDialer' | keyof Model
>

export type TLeadChildConstructor<T> = Omit<
  T,
  'toMarker' | 'toPoint' | 'getLink' | 'getPathname' | 'getDialer' | keyof Model
>

export type LeadSecondaryUserType = Potential<'buyer' | 'seller'>

export type FhaVaType = 'fha' | 'va' | 'other_government' | 'no'

export class Lead {
  id!: string
  bbysEligibleArea?: boolean
  leadSlackChannelUrl?: string
  callReview?: {
    ageRestrictedCommunity?: boolean
    agentCommunicationPreference?: string
    aiBotExitFlag?: string
    annualIncome?: string
    askedInvestorQualifyingQuestions?: boolean
    assistedIntroEligible?: string
    autoIntroCount?: string
    availability?: string
    bathroomsCount?: string
    bedroomsCount?: string
    bestContact?: string
    brokerages?: any
    brokeragesToExclude?: string[]
    buyerProgress?: string
    cashNeeded?: string
    clientInterestedIn?: string
    condition?: string
    consistentIncome?: boolean
    creditScore?: string
    downpaymentAmount?: string
    easyKnockSent?: boolean
    eligibleForHlti?: boolean
    existingLenderName?: string
    existingLenderPreApproved?: boolean
    existingLoanOfficerEmail?: string
    existingLoanOfficerName?: string
    existingLoanOfficerPhone?: string
    fhaVaLoan?: boolean | FhaVaType
    floodZone?: boolean
    foreclosure?: string
    garageSize?: string
    gatedCommunity?: boolean
    genderPreference?: string
    highTouch?: string
    homeListed?: string
    homeUseType?: string
    homeValue?: string
    howLongOwned?: string
    interestedInAssumableMortgage?: boolean
    interestedInLoan?: boolean
    interestedInOtherside?: 'Yes' | 'No' | 'N/A'
    languageRequirement?: string
    loanAmount?: string
    lotSize?: string
    manuallyDialedBadNumber?: boolean
    maxEquityUnlock?: string
    minEquityUnlock?: string
    minimumLoanAmount?: string
    monthlyDebt?: string
    needLender?: boolean
    needToMeetInNextDay?: string
    occupiedBy?: string
    othersideInfo?: {
      area?: string
      eligibile_for_hlti?: string
      fullAddress?: string
      price?: string
      primaryResidence?: string
      propertyType?: string
    }
    othersideStateBbysEligible?: boolean
    ownershipStatus?: string
    pitchedInvestorToClient?: boolean
    pool?: boolean
    preApprovalBorrowerIntent?: string
    preFabHome?: boolean
    preapproved?: string
    previousAgent?: string
    previousAgentBrokerage?: string
    previousAgentName?: string
    primaryResidence?: boolean
    qualificationMethod?: string
    qualificationType?: string
    relationship?: string
    remainingMortgageBalance?: string
    reverseMortgage?: boolean
    sameAgents?: string
    significantFoundationIssues?: boolean
    solarPanels?: boolean
    squareFeet?: string
    unpermittedAddition?: boolean
    why?: string
    yearBuilt?: string
  }
  createdAt?: string
  client?: Client
  dialerQueue?: string
  eligibilityDetails?: EligibilityCheck
  email?: string
  emailAlt?: string
  fileAttachments?: FileAttachment[]
  fullAddress?: string
  furthestStage?: string
  alreadyHasAgent?: boolean
  hasMinimumDownPayment?: boolean
  humanTimeline?: string
  introSentAt?: string
  isDeal?: boolean
  lastCallDate?: string
  lastStageUpdate?: string
  latitude?: string
  stateCode?: string
  areaId?: number
  areaType?: string
  leadUsers?: LeadUser[]
  leadUsersNamesString?: string
  longitude?: string
  lowerApplicationLink?: string
  modelName? = 'Lead'
  originalCreatedAt?: string
  providableName? = ''
  providerName? = ''
  name?: string
  notesToAgents?: string
  order?: Order
  partnerName?: string
  pendingActions?: PendingAction[]
  leadChannel?: LeadChannel
  leadViewers?: LeadViewers[]
  phoneAlt?: string
  phoneNumbers?: PhoneNumber[]
  phoneOffice?: PhoneNumber
  phoneMobile?: PhoneNumber
  phoneHome?: PhoneNumber
  phoneVerified?: boolean
  price?: number
  propertyType?: string
  propertyUuid?: string
  blindIntro?: boolean
  qualificationTalkedToClient?: boolean
  qualificationPreviousContract?: boolean
  qualificationIncorrectAddress?: boolean
  qualificationIsAnAgent?: boolean
  qualificationVerifiedPhone?: boolean
  qualificationIncorrectVoicemail?: boolean
  qualificationIncompleteName?: boolean
  qualificationNotAProperHouse?: boolean
  reasonForFail?: string
  referringOwnerType?: string
  salesNotes?: string
  secondaryUserType?: LeadSecondaryUserType
  source?: string
  sourcePageType?: string
  stage?: string
  stageAndFurthestStage?: string
  userType?: TLeadUserTypeServerSlug
  isFailed?: boolean
  tasks?: Task[]
  otherLeads?: Lead[]
  warmTransferEligible?: boolean
  warmTransferStarted?: boolean
  eligibleWarmTransferAgentQuantity?: number
  masterProperty?: {
    city_name?: string
    primary_line?: string
    state_name?: string
    zip_code?: string
  }
  marketingChannel?: string
  marketingSource?: string
  sellerBbysEligible?: string
  sourceForm?: string
  dialerQueueStatus?: 'unscheduled' | 'scheduled' | 'in_dialer'
  referringOwner?: any
  parentLead?: Lead
  partnerLender?: {
    externalLenderId: string
    lenderName: string
  }
  partnerLoanOfficer?: {
    loanOfficerId: string
    loanOfficerName: string
    loanOfficerPhone: string
  }

  constructor(leadAttributes: Potential<TLeadConstructor>) {
    if (leadAttributes) {
      Object.assign(this, leadAttributes)
    }
  }

  static create(leadResponse: Potential<LeadResponse>): Potential<Lead> {
    if (!leadResponse) {
      return
    }

    return new Lead(Lead.mapResponse(leadResponse))
  }

  static mapResponse(leadResponse: LeadResponse): TLeadConstructor {
    const leadUsers = getLeadUsers(leadResponse)
    const leadUsersNamesString = leadUsers?.map(leadUser => leadUser.prettyName).join(', ') || ''
    const isFailed = leadResponse.stage === 'failed'
    const order = getOrder(leadResponse)
    const client = getClient(leadResponse)
    const phoneNumbers = getPhoneNumbers(leadResponse)
    const tasks = getTasks(leadResponse.tasks)
    const phoneOffice = phoneNumbers?.find(byAttr('phoneType', 'office'))
    const phoneMobile = phoneNumbers?.find(byAttr('phoneType', 'cell'))
    const phoneHome = phoneNumbers?.find(byAttr('phoneType', 'home'))
    const parentLead = getParentLead(leadResponse)
    const eligibilityDetails = getEligibilityDetails(leadResponse)

    const otherLeads = order?.leads?.filter(lead => lead.id !== leadResponse.id) || []

    const normalizedStage = leadResponse.stage ? getLeadStageNameFromSlug(leadResponse.stage) : ''

    const stageAndFurthestStage = ['failed', 'nurture'].includes(leadResponse.stage || '')
      ? `${normalizedStage}${
          leadResponse.furthestStage
            ? ` (${getLeadStageNameFromSlug(leadResponse.furthestStage)})`
            : ''
        }`
      : normalizedStage

    return {
      ...leadResponse,
      leadUsers,
      leadUsersNamesString,
      isFailed,
      order,
      phoneNumbers,
      phoneOffice,
      phoneMobile,
      phoneHome,
      stageAndFurthestStage,
      tasks,
      client,
      otherLeads,
      parentLead,
      eligibilityDetails
    }
  }

  toMarker(color?: string) {
    return this.latitude && this.longitude
      ? new Marker({
          name: `${formatPrice(this.price, 'short')} ${this.fullAddress}`,
          description: this.name,
          type: 'lead',
          point: this.toPoint(),
          color
        })
      : undefined
  }

  toPoint() {
    return this.latitude && this.longitude
      ? new Point({
          latitude: this.latitude,
          longitude: this.longitude
        })
      : undefined
  }

  getLink(title: string | undefined = undefined) {
    return new Link({
      name: title || `${this.name} (${titleize(this.userType)})`,
      pathname: this.getPathname()
    })
  }

  getPathname() {
    return getAnyLeadPathName(this.id, this.userType)
  }

  getDialer = (currentUser: CurrentUser) =>
    new Dialer({
      currentUser,
      name: this.name!,
      phoneNumber: this.phoneNumbers?.[0].number!,
      phoneType: 'mobile',
      type: 'Lead',
      id: this.id
    })
}
