// @ts-strict
import { ReactNode, useState } from 'react'
import _ from 'lodash'
import { Box, Text, useToastContext } from '@foundation/components'
import { Flex } from '@foundation/components/Flex/styles'
import { styled } from '@foundation/themes/styles'
import { Button, Contents, Popup } from 'components'
import {
  usePayPerLeadBillingInfoController,
  usePayPerLeadPaymentInformationController
} from 'controllers'
import { Agent, Investor } from 'models'
import { BillingFrequencyForm, ReleaseBillingFrequencyForm } from '../BillingFrequency'

type PplBillingInfoProps = {
  provider: Agent | Investor
}

export type SettingsModalProps = {
  children: ReactNode
  onCancel: () => void
  title: string
}

const StyledPopup = styled(Popup)`
  width: fit-content;
  border-radius: 12px;
`

const SettingsModal = ({ children, onCancel, title }: SettingsModalProps) => {
  return (
    <>
      <StyledPopup title={title} onCancel={onCancel}>
        {children}
      </StyledPopup>
    </>
  )
}

export const PplBillingInfo = ({ provider }: PplBillingInfoProps) => {
  const [editBilingCycle, setEditBilingCycle] = useState(false)
  const [undoBilingCycle, setUndoBilingCycle] = useState(false)
  const modelName = provider.modelName || ''
  const { payPerLeadPaymentInformationQuery, clearCaches } =
    usePayPerLeadPaymentInformationController(provider.id, modelName)
  const { updateBillingCycle, releaseBillingCycle } = usePayPerLeadBillingInfoController(
    provider.id,
    modelName
  )
  const { data: paymentInformation } = payPerLeadPaymentInformationQuery
  const currentCycleFrequency = paymentInformation?.billingFrequency || 'month'
  const upcomingChange = paymentInformation?.upcomingBillingFrequencyChange
  const toast = useToastContext()

  const handleUpdateBillingCycle = (
    sellerCap: number,
    buyerCap: number,
    billingFrequency: string
  ) => {
    updateBillingCycle.mutate(
      { sellerCap: sellerCap * 100, buyerCap: buyerCap * 100, billingFrequency },
      {
        onSuccess: () => {
          clearCaches()

          toast({
            title: 'Upcoming Billing Cycle Frequency has been updated',
            status: 'success'
          })
        }
      }
    )
    setEditBilingCycle(false)
  }

  const handleReleaseBillingCycle = () => {
    releaseBillingCycle.mutate(void 0, {
      onSuccess: () => {
        clearCaches()

        toast({
          title: 'Upcoming Billing Cycle Frequency has been updated',
          status: 'success'
        })
      }
    })
    setUndoBilingCycle(false)
  }

  const displayCycle = (frequency: string) => {
    return frequency === 'month' ? 'Monthly' : 'Weekly'
  }

  const getUpcomingCycleFrequency = (currentCycleFrequency: string, upcomingChange: boolean) => {
    if (upcomingChange) {
      return currentCycleFrequency === 'month' ? 'Weekly' : 'Monthly'
    }

    return currentCycleFrequency
  }

  if (!paymentInformation || _.isEmpty(paymentInformation)) {
    return (
      <Box width="485px">
        <Contents title="Billing Information">
          <Box
            borderStyle="solid"
            borderColor="neutral5"
            borderWidth="thin"
            padding="$8"
            width="485px"
            borderRadius="xl"
            fontSize="base"
          >
            No billing information available
          </Box>
        </Contents>
      </Box>
    )
  }

  const upcomingCycleFrequency = getUpcomingCycleFrequency(currentCycleFrequency, upcomingChange)
  const frequencyWillChange = upcomingCycleFrequency !== currentCycleFrequency

  return (
    <>
      {editBilingCycle && (
        <SettingsModal title="Edit Billing Frequency" onCancel={() => setEditBilingCycle(false)}>
          <BillingFrequencyForm
            displayBuyerCap={true}
            buyerCap={paymentInformation.buyerMonthlyCap / 100}
            sellerCap={paymentInformation.monthlyCap / 100}
            submitText="Save"
            billingFrequency={currentCycleFrequency}
            onCancel={() => setEditBilingCycle(false)}
            onSubmit={(sellerCap: number, buyerCap: number, billingFrequency: string) =>
              handleUpdateBillingCycle(sellerCap, buyerCap, billingFrequency)
            }
          />
        </SettingsModal>
      )}

      {undoBilingCycle && (
        <SettingsModal
          title="Undo Billing Cycle Frequency Change"
          onCancel={() => setEditBilingCycle(false)}
        >
          <ReleaseBillingFrequencyForm
            submitText="Undo"
            onCancel={() => setUndoBilingCycle(false)}
            onSubmit={handleReleaseBillingCycle}
          />
        </SettingsModal>
      )}

      <Contents title="Billing Information">
        <Box
          borderStyle="solid"
          borderColor="neutral5"
          borderWidth="thin"
          padding="$8"
          width="485px"
          borderRadius="xl"
          fontSize="base"
        >
          {frequencyWillChange ? (
            <Flex width="100%" justifyContent="start">
              <Button as="underscore" onClick={() => setUndoBilingCycle(true)}>
                <Text size="sm">Undo Frequency Change</Text>
              </Button>
            </Flex>
          ) : (
            <Flex width="100%" justifyContent="start">
              <Button as="underscore" onClick={() => setEditBilingCycle(true)}>
                <Text size="sm">Edit Frequency</Text>
              </Button>
            </Flex>
          )}

          <Flex marginTop="$4" flexDirection="row">
            <Flex gap="$4" width="100%" alignItems="end">
              <Text size="sm" weight="bold">
                Current Cycle
              </Text>
              <Text size="sm">{displayCycle(currentCycleFrequency)}</Text>
            </Flex>

            <Flex gap="$4" width="100%" alignItems="end">
              <Text size="sm" weight="bold">
                Next Cycle
              </Text>
              {frequencyWillChange ? (
                <Text size="sm" color="primary5" weight="bold">
                  {displayCycle(upcomingCycleFrequency)}
                </Text>
              ) : (
                <Text size="sm">{displayCycle(currentCycleFrequency)}</Text>
              )}
            </Flex>
          </Flex>
        </Box>
      </Contents>
    </>
  )
}
