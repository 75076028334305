import { useState } from 'react'
import { PayPerLeadProviderSetting } from 'models'
import { USER_TYPES } from '../constants'

export const useLocationSettingsData = (
  providerType: string,
  initialLocationSettings?: PayPerLeadProviderSetting[]
) => {
  const [locationSettings, setLocationSettings] = useState<PayPerLeadProviderSetting[]>(
    initialLocationSettings || []
  )
  const [addedLocationSettings, setAddedLocationSettings] = useState<PayPerLeadProviderSetting[]>(
    []
  )

  const userTypes =
    providerType === 'Agent' ? [USER_TYPES.BUYER, USER_TYPES.SELLER] : [USER_TYPES.SELLER]

  const addLocationSettings = (slugs: string | string[], prices?: number | number[]) => {
    setLocationSettings(prevLocationSettings => {
      const uniqueSettings = new Set(
        prevLocationSettings.map(setting => `${setting.location}-${setting.userType}`)
      )

      const newSettings = []

      const slugArray = Array.isArray(slugs) ? slugs : [slugs]
      const priceArray = Array.isArray(prices) ? prices : [prices]

      slugArray.forEach((slug, index) => {
        const price = priceArray[index] ?? 0
        const active = price > 0

        userTypes.forEach(userType => {
          const settingKey = `${slug}-${userType}`
          if (!uniqueSettings.has(settingKey)) {
            uniqueSettings.add(settingKey)
            newSettings.push({ location: slug, userType: userType, price, active })
            setAddedLocationSettings(prev => [
              ...prev,
              { location: slug, userType: userType, price, active }
            ])
          } else {
            const existingSetting = prevLocationSettings.find(
              setting => setting.location === slug && setting.userType === userType
            )

            if (existingSetting) {
              existingSetting.price = price
              existingSetting.active = active
            }
          }
        })
      })

      return [...prevLocationSettings, ...newSettings]
    })
  }

  const removeLocationSetting = (locationSlug: string) => {
    const newLocationSettings = locationSettings.filter(ls => ls.location !== locationSlug)
    setLocationSettings(newLocationSettings)
  }

  const toggleActive = (index: number) => {
    const newLocationSettings = [...locationSettings]
    if ((newLocationSettings[index].price ?? 0) === 0) {
      return
    }

    newLocationSettings[index].active = !newLocationSettings[index].active
    setLocationSettings(newLocationSettings)
  }

  const setPrice = (index: number, price: number) => {
    const newLocationSettings = [...locationSettings]
    const setting = newLocationSettings[index]

    setting.price = price > 0 ? price : null
    setting.active = price > 0

    setLocationSettings(newLocationSettings)
  }

  return {
    locationSettings,
    addedLocationSettings,
    addLocationSettings,
    removeLocationSetting,
    toggleActive,
    setPrice
  }
}
