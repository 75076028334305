// @ts-strict
import { useState } from 'react'
import { SERVICE_STATES } from '@constants/bbysServiceStates'
import { Button } from '@foundation/components'
import { ButtonForm, CheckBoxField, FormField, Select, TextInput } from 'components'
import { useGeneratedDocumentsController } from 'controllers'
import { CCBBYSLead } from 'models'
import { capitalizeWords } from 'utils'

type TLeadNewGeneratedDocumentButtonFormProps = {
  lead?: CCBBYSLead
}

const agreementFields = [
  'title',
  'date',
  'valid_date',
  'client_1',
  'client_2',
  'lo_company_name',
  'dr_address',
  'agent_valuation',
  'eu_amount',
  'first_lien',
  'second_lien',
  'inspection_cost',
  'program_fee',
  'property_conditions_and_repairs',
  'loan_payoff_value',
  'bbys_loan_amount'
]

const payoffDemandFields = [
  'title',
  'date',
  'clients',
  'dr_address_line_1',
  'dr_address_line_2',
  'loan_number',
  'sale_price',
  'equity_unlock_loan_amount',
  'program_fee',
  'inspection_cost',
  'bbys_loan_amount',
  'program_fee_adjustment',
  'recording_fee',
  'notary_fee',
  'di_taxes',
  'total_due',
  'valid_date'
]

const agreementOptionalFields = ['client_2', 'lo_company_name', 'property_conditions_and_repairs']

const generateDocumentSlugs = [
  {
    name: 'Dynamic Buy Before You Sell Agreement',
    value: 'conditional-document-formatting',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: [
      'title',
      'date',
      'client_1',
      'client_1_email',
      'client_2',
      'client_2_email',
      'cover_names',
      'dr_address',
      'valid_date',
      'bbys_loan_amount',
      'eu_amount',
      'equity_boost',
      'second_lien',
      'program_length',
      'program_fee_percentage',
      'inspection_cost',
      'agent_valuation',
      'loan_payoff_value',
      'property_conditions_and_repairs',
      'lo_company_name',
      'final_eligibility_flag',
      'conditional_eligibility_flag',
      'required_home_repairs_flag',
      'closing_management_flag',
      'program_fee_flag',
      'no_buy_before_you_sell_loan_flag',
      'equity_boost_election_flag',
      'texas_flag'
    ],
    optional: ['client_2', 'client_2_email', 'cover_names', 'property_conditions_and_repairs']
  },
  {
    name: 'Conditional BBYS Agreement (Service State)',
    value: 'service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Conditional BBYS Agreement (Non-Service State)',
    value: 'non-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Conditional BBYS Agreement (No Closing Support Fee)',
    value: 'no-closing-support-fee-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (Service State)',
    value: 'final-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement (Final)',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (Non-Service State)',
    value: 'final-non-service-state-agreement',
    title: 'HomeLight Buy Before You Sell Agreement (Final)',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Final BBYS Agreement (No Closing Support Fee)',
    value: 'final-no-closing-support-fee-agreement',
    title: 'HomeLight Buy Before You Sell Agreement',
    fields: agreementFields,
    optional: agreementOptionalFields
  },
  {
    name: 'Upside Guarantee',
    value: 'upside-guarantee',
    title: 'HomeLight Upside Guarantee',
    fields: [
      'title',
      'date',
      'valid_date',
      'client_1',
      'client_1_email',
      'client_2',
      'client_2_email',
      'dr_address',
      'agent_name',
      'agent_valuation',
      'agent_fee',
      'loan_payoff_value'
    ],
    optional: ['client_2', 'client_2_email']
  },
  {
    name: 'Payoff Demand HLHL Flagstar',
    value: 'payoff-demand-hlhl-flagstar',
    title: 'Payoff Demand',
    fields: payoffDemandFields,
    optional: []
  },
  {
    name: 'Payoff Demand HLHL Florida Capital Bank',
    value: 'payoff-demand-hlhl-florida-capital-bank',
    title: 'Payoff Demand',
    fields: payoffDemandFields,
    optional: []
  },
  {
    name: 'Payoff Demand HLHL Equity',
    value: 'payoff-demand-hlhl-equity',
    title: 'Payoff Demand',
    fields: payoffDemandFields,
    optional: []
  },
  {
    name: 'Payoff Demand TLS Flagstar',
    value: 'payoff-demand-tls-flagstar',
    title: 'Payoff Demand',
    fields: payoffDemandFields,
    optional: []
  },
  {
    name: 'Termination Fee',
    value: 'termination-fee',
    title: 'Termination Fee',
    fields: [
      'title',
      'date',
      'clients',
      'dr_address_line_1',
      'dr_address_line_2',
      'loan_number',
      'termination_fee',
      'program_fee',
      'inspection_cost',
      'recording_fee',
      'di_taxes',
      'total_due',
      'valid_date'
    ],
    optional: []
  },
  {
    name: 'Program Fee',
    value: 'program-fee',
    title: 'Program Fee',
    fields: [
      'title',
      'date',
      'clients',
      'dr_address_line_1',
      'dr_address_line_2',
      'loan_number',
      'program_fee',
      'inspection_cost',
      'administration_fee',
      'di_taxes',
      'total_due',
      'valid_date'
    ],
    optional: []
  }
]

type LeadGeneratedDocumentSlugs =
  | 'service-state-agreement'
  | 'non-service-state-agreement'
  | 'no-closing-support-fee-agreement'
  | 'upside-guarantee'
  | 'payoff-demand-hlhl-flagstar'
  | 'payoff-demand-hlhl-florida-capital-bank'
  | 'payoff-demand-hlhl-equity'
  | 'payoff-demand-tls-flagstar'
  | 'conditional-document-formatting'

// | 'lennar-agreement'
// | 'payoff-demand'
// | 'termination-fee-invoice'
// | 'program-fee-invoice'

export type TLeadGeneratedDocumentPayload = {
  [key: string]: string
}

export const LeadNewGeneratedDocumentButtonForm = ({
  lead
}: TLeadNewGeneratedDocumentButtonFormProps) => {
  // @ts-ignore
  const { generateDocument } = useGeneratedDocumentsController({ bbysLeadId: lead?.bbysLead.id })
  const [payload, setPayload] = useState(
    initialPayload('conditional-document-formatting') as TLeadGeneratedDocumentPayload
  )
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function initialPayload(slug: LeadGeneratedDocumentSlugs): TLeadGeneratedDocumentPayload {
    const payload: TLeadGeneratedDocumentPayload = { slug }
    const config = generateDocumentSlugs.find(s => s.value === slug) || generateDocumentSlugs[0]
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })
    const formatCurrency = (value: number) => formatter.format(value)
    const formats = {
      administration_fee: formatCurrency,
      agent_fee: formatCurrency,
      agent_valuation: formatCurrency,
      bbys_loan_amount: formatCurrency,
      di_taxes: formatCurrency,
      equity_unlock_loan_amount: formatCurrency,
      equity_boost: formatCurrency,
      eu_amount: formatCurrency,
      first_lien: formatCurrency,
      inspection_cost: formatCurrency,
      loan_payoff_value: formatCurrency,
      notary_fee: formatCurrency,
      program_fee_adjustment: formatCurrency,
      program_fee: formatCurrency,
      recording_fee: formatCurrency,
      sale_price: formatCurrency,
      second_lien: formatCurrency,
      termination_fee: formatCurrency,
      total_due: formatCurrency,
      purchase_agreement_price: formatCurrency
    }
    const expenses = lead?.bbysProviderLead?.providerLeadExpenses

    const getExpenseValueBySlug = (slug: string) => {
      const expenseBySlug = expenses?.find(expense => {
        return expense.slug === slug
      })
      return expenseBySlug?.value
    }
    const basicFields = {
      title: (): string =>
        `${config.title}${
          config.fields.includes('conditional_eligibility_flag')
            ? ` ${!basicFields.conditional_eligibility_flag() ? 'Final' : 'Conditional'}`
            : ''
        } - ${lead?.fullAddress} - ${[
          lead?.bbysLead?.primaryClientNameOnTitle,
          lead?.bbysLead?.additionalClientNameOnTitle
        ]
          .filter(c => !!c)
          .join(' & ')}`,
      administration_fee: (): number =>
        lead?.bbysLead?.administrationFee ? parseFloat(lead?.bbysLead?.administrationFee) : 0,
      agent_name: (): string => lead?.bbysLead?.departingPropertySellerAgent?.name || '',
      agent_fee: (): number => parseFloat(lead?.bbysLead?.dpTotalAgentCommission || '0'),
      agent_valuation: (): number =>
        parseFloat(
          lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue || '0'
        ),
      bbys_loan_amount: (): number =>
        lead?.bbysLead?.buyBeforeYouSellLoanAmount
          ? parseFloat(lead?.bbysLead?.buyBeforeYouSellLoanAmount)
          : 0,
      client_1: (): string => lead?.bbysLead?.primaryClientNameOnTitle || '',
      client_1_email: (): string => lead?.email || '',
      client_2: (): string => lead?.bbysLead?.additionalClientNameOnTitle || '',
      client_2_email: (): string => lead?.emailAlt || '',
      lo_company_name: (): string => lead?.bbysLead?.loanOfficer?.companyName || '',
      clients: (): string =>
        [lead?.bbysLead?.primaryClientNameOnTitle, lead?.bbysLead?.additionalClientNameOnTitle]
          .filter(c => !!c)
          .join(' & '),
      date: (): string =>
        new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      di_taxes: (): number =>
        lead?.bbysLead?.documentaryAndIntangibleTaxes
          ? parseFloat(lead?.bbysLead?.documentaryAndIntangibleTaxes)
          : 0,
      dr_address: (): string => lead?.fullAddress || '',
      dr_address_line_1: (): string => lead?.fullAddress?.split(', ')[0] || '',
      dr_address_line_2: (): string => {
        return lead?.fullAddress?.split(', ').slice(1).join(', ') || ''
      },
      equity_unlock_loan_amount: (): number =>
        lead?.bbysLead?.equityUnlockLoanFundingAmount
          ? parseFloat(lead?.bbysLead?.equityUnlockLoanFundingAmount)
          : 0,
      eu_amount: (): number =>
        lead?.bbysLead?.dpMaxDownpaymentEquity
          ? parseFloat(lead?.bbysLead?.dpMaxDownpaymentEquity)
          : 0,
      first_lien: (): number =>
        lead?.bbysLead?.dpFirstPositionPayoffs
          ? parseFloat(lead?.bbysLead?.dpFirstPositionPayoffs)
          : 0,
      inspection_cost: (): number =>
        getExpenseValueBySlug('target_property_pest_inspections')
          ? parseFloat(getExpenseValueBySlug('target_property_pest_inspections') ?? '0')
          : 0,
      loan_number: (): string => lead?.bbysLead?.buyBeforeYouSellLoanNumber || '',
      loan_payoff_value: (): number =>
        lead?.bbysLead?.loanPayoffValue ? parseFloat(lead?.bbysLead?.loanPayoffValue) : 0,
      cover_names: (): string => {
        const nameOfTrust = lead?.bbysLead?.nameOfTrust ?? ''
        if (lead?.bbysLead?.departingResidenceHeldInTrust) {
          return nameOfTrust
        }
        const primaryClient = lead?.bbysLead?.primaryClientNameOnTitle ?? ''
        const additionalClient = lead?.bbysLead?.additionalClientNameOnTitle ?? ''
        return [primaryClient, additionalClient].filter(Boolean).join(' & ')
      },
      name_of_trust: (): string => {
        const nameOfTrust = lead?.bbysLead?.nameOfTrust ?? ''
        return lead?.bbysLead?.departingResidenceHeldInTrust ? nameOfTrust : ''
      },
      notary_fee: (): number =>
        lead?.bbysLead?.notaryFee ? parseFloat(lead?.bbysLead?.notaryFee) : 0,
      principle_balance: (): number =>
        basicFields.bbys_loan_amount() + basicFields.program_fee() + basicFields.inspection_cost(),
      property_conditions_and_repairs: (): string => '',
      program_fee: (): number =>
        Math.max(
          9000,
          (lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue
            ? parseFloat(
                lead?.bbysLead?.agentEstimatedHomeValue ||
                  lead?.bbysLead?.lenderEstimatedHomeValue ||
                  '0'
              )
            : 0) *
            (lead?.bbysLead?.dpTargetTradeInFeeRate
              ? parseFloat(lead?.bbysLead?.dpTargetTradeInFeeRate)
              : 0)
        ),
      program_fee_adjustment: (): number =>
        (lead?.bbysLead?.programFeeSurcharge
          ? parseFloat(lead?.bbysLead?.programFeeSurcharge)
          : 0) -
        (lead?.bbysLead?.programFeeDiscount ? parseFloat(lead?.bbysLead?.programFeeDiscount) : 0),
      program_fee_percentage: (): number =>
        lead?.bbysLead?.dpTargetTradeInFeeRate
          ? parseFloat(lead?.bbysLead?.dpTargetTradeInFeeRate) * 100
          : 0,
      program_length: (): number =>
        lead?.bbysLead?.programLength ? parseFloat(lead?.bbysLead?.programLength) : 120,
      recording_fee: (): number =>
        lead?.bbysLead?.recordingFee ? parseFloat(lead?.bbysLead?.recordingFee) : 0,
      sale_price: (): number =>
        lead?.bbysLead?.departingResidenceFinalSalePrice
          ? parseFloat(lead?.bbysLead?.departingResidenceFinalSalePrice)
          : 0,
      second_lien: (): number =>
        lead?.bbysLead?.dpNonFirstPositionBalances
          ? parseFloat(lead?.bbysLead?.dpNonFirstPositionBalances)
          : 0,
      termination_fee: (): number =>
        lead?.bbysLead?.terminationFee ? parseFloat(lead?.bbysLead?.terminationFee) : 0,
      total_due: (): number => {
        switch (payload.slug) {
          case 'payoff-demand-hlhl-flagstar':
          case 'payoff-demand-hlhl-florida-capital-bank':
          case 'payoff-demand-hlhl-equity':
          case 'payoff-demand-tls-flagstar':
            return (
              basicFields.bbys_loan_amount() +
              basicFields.program_fee_adjustment() +
              basicFields.recording_fee() +
              basicFields.notary_fee() +
              basicFields.di_taxes()
            )
          case 'termination-fee':
            return (
              basicFields.termination_fee() +
              basicFields.program_fee() +
              basicFields.inspection_cost() +
              basicFields.recording_fee() +
              basicFields.di_taxes()
            )
          case 'program-fee':
            return (
              basicFields.program_fee() +
              basicFields.inspection_cost() +
              basicFields.administration_fee() +
              basicFields.di_taxes()
            )
          default:
            return 0
        }
      },
      valid_date: (): string => {
        const now = new Date()
        now.setDate(now.getDate() + 30)
        return now.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      },
      equity_unlock_amount_for_new_home: (): string =>
        lead?.bbysLead?.dpMaxDownpaymentEquity || '0',
      equity_boost: (): string => lead?.bbysLead?.equityBoostAmount ?? '0',
      amount_for_outstanding_balances: (): string =>
        lead?.bbysLead?.dpNonFirstPositionBalances || '0',
      program_period: (): string => lead?.bbysLead?.programLength || '0',
      purchase_agreement_price: (): string =>
        lead?.bbysLead?.agentEstimatedHomeValue || lead?.bbysLead?.lenderEstimatedHomeValue || '0',
      conditional_eligibility_flag: (): boolean =>
        lead?.bbysLead?.inspectionsRequired && !lead?.bbysLead?.inspectionCompleteDate
          ? true
          : false,
      final_eligibility_flag: (): boolean => !basicFields.conditional_eligibility_flag(),
      required_home_repairs_flag: (): boolean =>
        lead?.bbysLead?.departingResidenceRequiredRepairsPreFunding ? true : false,
      closing_management_flag: (): boolean =>
        lead?.stateCode &&
        SERVICE_STATES.includes(lead.stateCode) &&
        lead?.bbysLead?.sourcePartner?.slug !== 'tls'
          ? true
          : false,
      program_fee_flag: (): boolean => (lead?.bbysLead?.contingencyRemovalOnly ? true : false),
      no_buy_before_you_sell_loan_flag: (): boolean =>
        lead?.bbysLead?.contingencyRemovalOnly ? true : false,
      equity_boost_election_flag: (): boolean => (lead?.bbysLead?.equityBoost ? true : false),
      texas_flag: (): boolean => lead?.stateCode === 'TX'
    }

    for (const field of config.fields) {
      if (field in basicFields) {
        const value = basicFields[field]()
        payload[field] = formats[field] ? formats[field](value) : value.toString()
      }
    }

    return payload
  }

  function onChangeSlug(slug: LeadGeneratedDocumentSlugs) {
    setPayload(initialPayload(slug))
  }

  function onChangeAttribute(field: string, value: string | boolean) {
    // Prepare a new payload by copying the current state
    let updatedPayload = { ...payload }

    // If it's a boolean flag (final or conditional), cast it to a string (since payload expects string)
    if (field === 'final_eligibility_flag' && value) {
      updatedPayload = {
        ...updatedPayload,
        final_eligibility_flag: 'true',
        conditional_eligibility_flag: 'false' // Uncheck conditional if final is selected
      }
    } else if (field === 'conditional_eligibility_flag' && value) {
      updatedPayload = {
        ...updatedPayload,
        final_eligibility_flag: 'false',
        conditional_eligibility_flag: 'true' // Uncheck final if conditional is selected
      }
    } else {
      // For non-boolean values, just set the field directly
      updatedPayload[field] = value.toString()
    }

    // Update title dynamically based on the eligibility flag
    const titleBase = 'HomeLight Buy Before You Sell Agreement'
    let titleSuffix = ''

    if (updatedPayload.final_eligibility_flag === 'true') {
      titleSuffix = ' Final'
    } else if (updatedPayload.conditional_eligibility_flag === 'true') {
      titleSuffix = ' Conditional'
    }

    updatedPayload.title = `${titleBase}${titleSuffix} - ${lead?.fullAddress} - ${[
      lead?.bbysLead?.primaryClientNameOnTitle,
      lead?.bbysLead?.additionalClientNameOnTitle
    ]
      .filter(c => !!c)
      .join(' & ')}`

    // Update the payload in the state
    setPayload(updatedPayload)
  }

  async function onSubmit() {
    setIsLoading(true)
    generateDocument.mutate(payload, {
      onError: () => setIsLoading(false),
      onSuccess: () => {
        setIsLoading(false)
        setShowModal(false)
      }
    })
  }

  const config =
    generateDocumentSlugs.find(s => s.value === payload.slug) || generateDocumentSlugs[0]
  let isFormValid = true
  config.fields.forEach(field => {
    if (!config.optional.includes(field) && !payload[field]) {
      isFormValid = false
    }
  })
  return (
    <>
      <Button variant="outline" color="neutralLight" onClick={() => setShowModal(true)}>
        Generate Document
      </Button>
      <ButtonForm
        buttonText="Generate Document"
        title="Generate Document"
        hideTriggerButton
        showForm={showModal}
        toggleShowForm={() => !isLoading && setShowModal(!showModal)}
        handleClose={() => !isLoading && setShowModal(false)}
        handleHover={() => {}}
        showError={false}
        error=""
        errorMessage=""
        errors={{}}
        isFormValid={isFormValid}
        isLoading={isLoading}
        onSubmit={onSubmit}
        resetForm={() => {}}
      >
        <FormField title="Choose a Template" htmlFor="slug" required>
          <Select
            id="slug"
            options={generateDocumentSlugs}
            value={payload.slug}
            onChange={val => onChangeSlug(val as LeadGeneratedDocumentSlugs)}
            addBlank
          />
        </FormField>
        {config.fields.map((field, i) => (
          <FormField
            key={field}
            title={capitalizeWords(field.split('_').join(' '))}
            htmlFor={`field-${field}`}
            required={!config.optional.includes(field)}
          >
            {field.includes('flag') ? (
              <CheckBoxField
                name={field}
                checked={payload[field] === 'true'}
                onChange={e => onChangeAttribute(field, e.target.checked.toString())}
              />
            ) : (
              <TextInput
                key={i}
                id={`field-${field}`}
                name={field}
                value={payload[field]}
                onChange={val => onChangeAttribute(field, val)}
              />
            )}
          </FormField>
        ))}
      </ButtonForm>
    </>
  )
}
